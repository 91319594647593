:root {
    --color-black: #142a34;
    --color-green: #449672;
    --color-purple: #9a3293;
    --color-red: #dc2f53;
    --color-teal: #42a3ae;
    --color-white: #ffffff;
    --color-yellow: #f3a51f;
    --color-light-blue: #49e4cd;
    --color-highlight: #1aad97;
}

* {
    border: 0;
    box-sizing: border-box;
    cursor: default;
    font: inherit;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    display: table;
    font-size: 16px;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    background-color: var(--color-black);
    display: table-cell;
    height: 100%;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
