#kes-page-header {
    background-image: url('../../assets/header-background.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
}

#kes-header-overlay {
    align-items: center;
    background: linear-gradient(
        180deg,
        rgba(20, 42, 52, 0) 0%,
        rgba(20, 42, 52, 1) 75%,
        rgba(20, 42, 52, 1) 100%
    );
    display: flex;
    justify-content: center;
    width: 100%;
}
@media only screen and (max-width: 600px) {
    #kes-page-header {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-header-overlay {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-summit-logo {
        width: 62vw;
    }
}

@media only screen and (min-width: 600px) {
    #kes-page-header {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-header-overlay {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-summit-logo {
        width: 62vw;
    }
}

@media only screen and (min-width: 768px) {
    #kes-page-header {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-header-overlay {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-summit-logo {
        width: 62vw;
    }
}

@media only screen and (min-width: 992px) {
    #kes-page-header {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-header-overlay {
        height: calc(100vw * (204 / 360));
        width: 100vw;
    }

    #kes-summit-logo {
        width: 62vw;
    }
}

@media only screen and (min-width: 1200px) {
    #kes-page-header {
        height: calc(25vw * (204 / 360));
        width: 100vw;
    }

    #kes-header-overlay {
        height: calc(25vw * (204 / 360));
        width: 100vw;
    }

    #kes-summit-logo {
        width: 15vw;
    }
}
