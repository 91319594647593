#kes-page-footer {
    align-items: center;
    background: transparent;
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%;
}

#kes-page-footer a {
    text-decoration: none;
}

#odd-logo-wrapper {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

#odd-logo-text {
    color: var(--color-white);
    font-family: 'Archivo', sans-serif;
    font-stretch: condensed;
    font-weight: 200;
    letter-spacing: 0.03em;
    text-align: left;
    text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
    #kes-page-footer {
        padding: 5vw 0 12.5vw 0;
    }

    #odd-logo-wrapper {
        column-gap: 2.25vw;
        width: 38vw;
    }

    #odd-logo-image {
        height: calc(16vw * (33 / 50));
        padding-bottom: 0.5vw;
        width: 16vw;
    }

    #odd-logo-text {
        font-size: 0.625em;
        line-height: 125%;
    }
}

@media only screen and (min-width: 600px) {
    #kes-page-footer {
        padding: 2.5vw 0 6.75vw 0;
    }

    #odd-logo-wrapper {
        column-gap: 1.25vw;
        width: 27.5vw;
    }

    #odd-logo-image {
        height: calc(12vw * (33 / 50));
        padding-bottom: 0.5vw;
        width: 12vw;
    }

    #odd-logo-text {
        font-size: 0.8em;
        line-height: 130%;
    }
}

@media only screen and (min-width: 768px) {
    #kes-page-footer {
        padding: 2vw 0 5vw 0;
    }

    #odd-logo-wrapper {
        column-gap: 1.25vw;
        width: 25vw;
    }

    #odd-logo-image {
        height: calc(11vw * (33 / 50));
        padding-bottom: 0.25vw;
        width: 11vw;
    }

    #odd-logo-text {
        font-size: 0.875em;
        line-height: 130%;
    }
}

@media only screen and (min-width: 992px) {
    #kes-page-footer {
        padding: 2vw 0 5vw 0;
    }

    #odd-logo-wrapper {
        column-gap: 1.15vw;
        width: 22.5vw;
    }

    #odd-logo-image {
        height: calc(10vw * (33 / 50));
        padding-bottom: 0.25vw;
        width: 10vw;
    }

    #odd-logo-text {
        font-size: 1em;
        line-height: 130%;
    }
}

@media only screen and (min-width: 1200px) {
    #kes-page-footer {
        padding: 1vw 0 2.5vw 0;
    }

    #odd-logo-wrapper {
        column-gap: 0.75vw;
        width: 12.5vw;
    }

    #odd-logo-image {
        height: calc(5vw * (33 / 50));
        padding-bottom: 0.25vw;
        width: 5vw;
    }

    #odd-logo-text {
        font-size: 0.75em;
        line-height: 130%;
    }
}
