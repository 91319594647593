#kes-support-modal {
    align-items: center;
    display: flex;
    height: max(100vh, 100%);
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: max(100vw, 100%);
    z-index: 100;
}

#kes-support-modal #kes-modal-content {
    display: flex;
    flex-direction: column;
}

#kes-support-modal #kes-modal-return-button {
    background: transparent;
    color: var(--color-black);
    cursor: pointer;
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    margin-right: auto;
}

#kes-support-modal #kes-modal-return-button:hover {
    color: var(--color-white);
}

#kes-support-modal #kes-modal-image {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#kes-support-modal .kes-modal-text {
    color: var(--color-white);
    display: block;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
}

#kes-support-modal .kes-modal-input {
    background: transparent;
    color: var(--color-white);
    cursor: text;
    font-family: 'Sora', sans-serif;
    font-weight: 300;
    width: 100%;
}

#kes-support-modal .kes-modal-input::placeholder {
    color: var(--color-white);
    font-family: 'Sora', sans-serif;
    font-weight: 300;
    opacity: 0.7;
}

#kes-support-modal input.kes-modal-input {
    height: unset;
}

#kes-support-modal #kes-modal-send-button,
#kes-support-modal #kes-modal-sent-message {
    background: transparent;
    border: 4px solid var(--color-white);
    color: var(--color-white);
    cursor: pointer;
    font-family: 'Pathway Gothic One', sans-serif;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

#kes-support-modal #kes-modal-send-button:not(.kes-button-disabled):hover {
    background-color: var(--color-white);
    box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.15);
    color: var(--color-black);
}

#kes-support-modal #kes-modal-send-button.kes-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

#kes-support-modal #kes-modal-sent-message {
    margin: auto 0;
}

#kes-support-modal #kes-modal-sent-message-square {
    background-color: var(--color-highlight);
    height: 4px;
    margin: 4px 0 auto 0;
    width: 0%;
}

#kes-support-modal .kes-modal-category-capitalism {
    background-color: var(--color-green);
}

#kes-support-modal .kes-modal-category-technology {
    background-color: var(--color-purple);
}

#kes-support-modal .kes-modal-category-tendences {
    background-color: var(--color-teal);
}

#kes-support-modal .kes-modal-category-labor {
    background-color: var(--color-red);
}

#kes-support-modal .kes-modal-category-social {
    background-color: var(--color-yellow);
}

#kes-support-modal.kes-modal-sent #kes-modal-content {
    background-color: var(--color-light-blue);
}

@media only screen and (max-width: 600px) {
    #kes-support-modal #kes-modal-content {
        height: 100%;
        padding: 8.75vw 8.75vw 25vw 8.75vw;
        width: 100%;
    }

    #kes-support-modal #kes-modal-return-button {
        font-size: 0.75em;
        line-height: 125%;
        margin-bottom: 2.5vw;
    }

    #kes-support-modal #kes-modal-image {
        display: none;
        height: 31.25vw;
        margin: 2.5vw 25.625vw 5vw 25.625vw;
        width: 31.25vw;
    }

    #kes-support-modal .kes-modal-text {
        font-size: 1em;
        line-height: 140%;
        margin-bottom: 2.5vw;
        margin-top: 5vw;
    }

    #kes-support-modal .kes-modal-input {
        font-size: 1.125em;
        line-height: 125%;
    }

    #kes-support-modal input.kes-modal-input {
        height: unset;
        margin-bottom: 7.75vw;
    }

    #kes-support-modal textarea.kes-modal-input {
        height: 27.75vw;
    }

    #kes-support-modal #kes-modal-send-button {
        font-size: 1em;
        margin: 4.5vw 0 0 0;
        padding: 2.25vw 0;
    }

    #kes-support-modal #kes-modal-sent-message {
        font-size: 1em;
        margin: auto 0 0 0;
        padding: 2.25vw 0;
    }
}

@media only screen and (min-width: 600px) {
    #kes-support-modal #kes-modal-content {
        height: 100%;
        padding: 12.5vw 12.5vw 20vw 12.5vw;
        width: 100%;
    }

    #kes-support-modal #kes-modal-return-button {
        font-size: 0.875em;
        line-height: 125%;
        margin-bottom: 1.5vw;
    }

    #kes-support-modal #kes-modal-image {
        display: none;
        height: 30vw;
        margin: 1.5vw 22.5vw 3vw 22.5vw;
        width: 30vw;
    }

    #kes-support-modal .kes-modal-text {
        font-size: 1.125em;
        line-height: 140%;
        margin-bottom: 1.5vw;
        margin-top: 3vw;
    }

    #kes-support-modal .kes-modal-input {
        font-size: 1.25em;
        line-height: 125%;
    }

    #kes-support-modal input.kes-modal-input {
        height: unset;
        margin-bottom: 6.5vw;
    }

    #kes-support-modal textarea.kes-modal-input {
        height: 16.75vw;
    }

    #kes-support-modal #kes-modal-send-button {
        font-size: 1.125em;
        margin: 2.75vw 0 0 0;
        padding: 2.25vw 0;
    }

    #kes-support-modal #kes-modal-sent-message {
        font-size: 1.125em;
        margin: auto 0 0 0;
        padding: 2.25vw 0;
    }
}

@media only screen and (min-width: 768px) {
    #kes-support-modal #kes-modal-content {
        height: 100%;
        padding: 7.5vw 15vw 15vw 15vw;
        width: 100%;
    }

    #kes-support-modal #kes-modal-return-button {
        font-size: 1em;
        line-height: 125%;
        margin-bottom: 0;
    }

    #kes-support-modal #kes-modal-image {
        display: block;
        height: 30vw;
        margin: 1.25vw 20vw 3vw 20vw;
        width: 30vw;
    }

    #kes-support-modal .kes-modal-text {
        font-size: 1.25em;
        line-height: 140%;
        margin-bottom: 1.5vw;
        margin-top: 0;
    }

    #kes-support-modal .kes-modal-input {
        font-size: 1.375em;
        line-height: 125%;
    }

    #kes-support-modal input.kes-modal-input {
        height: unset;
        margin-bottom: 6.5vw;
    }

    #kes-support-modal textarea.kes-modal-input {
        height: 13vw;
    }

    #kes-support-modal #kes-modal-send-button {
        font-size: 1.375em;
        margin: 2vw 0 0 0;
        padding: 2.25vw 0;
    }

    #kes-support-modal #kes-modal-sent-message {
        font-size: 1.375em;
        margin: auto 0 0 0;
        padding: 2.25vw 0;
    }
}

@media only screen and (min-width: 992px) {
    #kes-support-modal #kes-modal-content {
        height: 100%;
        padding: 10vw 20vw 17.5vw 20vw;
        width: 100%;
    }

    #kes-support-modal #kes-modal-return-button {
        font-size: 1.125em;
        line-height: 125%;
    }

    #kes-support-modal #kes-modal-image {
        height: 30vw;
        margin: 1.25vw 15vw 3.75vw 15vw;
        width: 30vw;
    }

    #kes-support-modal .kes-modal-text {
        font-size: 1.375em;
        line-height: 140%;
        margin-bottom: 1.25vw;
    }

    #kes-support-modal .kes-modal-input {
        font-size: 1.5em;
        line-height: 125%;
    }

    #kes-support-modal input.kes-modal-input {
        height: unset;
        margin-bottom: 5vw;
    }

    #kes-support-modal textarea.kes-modal-input {
        height: 10vw;
    }

    #kes-support-modal #kes-modal-send-button {
        font-size: 1.5em;
        margin: 1.75vw 0 0 0;
        padding: 2.25vw 0;
    }

    #kes-support-modal #kes-modal-sent-message {
        font-size: 1.5em;
        margin: auto 0 0 0;
        padding: 2.25vw 0;
    }
}

@media only screen and (min-width: 1200px) {
    #kes-support-modal #kes-modal-content {
        height: 100%;
        padding: 2.5vw 2.5vw 5vw 2.5vw;
        width: 100%;
    }

    #kes-support-modal #kes-modal-return-button {
        font-size: 0.875em;
        line-height: 125%;
    }

    #kes-support-modal #kes-modal-image {
        height: 15vw;
        margin: 1vw 40vw 1.5vw 40vw;
        width: 15vw;
    }

    #kes-support-modal .kes-modal-text {
        font-size: 1.125em;
        line-height: 140%;
        margin: 0 25vw 0.5vw 25vw;
    }

    #kes-support-modal .kes-modal-input {
        font-size: 1.25em;
        width: 45vw;
        line-height: 125%;
        margin: 0 25vw;
    }

    #kes-support-modal input.kes-modal-input {
        height: unset;
        margin-bottom: 3vw;
    }

    #kes-support-modal textarea.kes-modal-input {
        height: 17vw;
    }

    #kes-support-modal #kes-modal-send-button,
    #kes-support-modal #kes-modal-sent-message {
        font-size: 1.25em;
        margin: auto auto 0 auto;
        max-width: 25vw;
        padding: 1vw 0;
    }
}
