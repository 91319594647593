#kes-support-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

#kes-support-page-intro {
    color: var(--color-light-blue);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #kes-support-page-intro {
        font-size: 1em;
        line-height: 125%;
        margin: -11vw 18vw 5.5vw 18vw;
    }
}

@media only screen and (min-width: 600px) {
    #kes-support-page-intro {
        font-size: 1.25em;
        line-height: 140%;
        margin: -12.5vw 20vw 2.75vw 20vw;
    }
}

@media only screen and (min-width: 768px) {
    #kes-support-page-intro {
        font-size: 1.5em;
        line-height: 140%;
        margin: -12.5vw 25vw 2.75vw 25vw;
    }
}

@media only screen and (min-width: 992px) {
    #kes-support-page-intro {
        font-size: 1.75em;
        line-height: 140%;
        margin: -15vw 25vw 1vw 25vw;
    }
}

@media only screen and (min-width: 1200px) {
    #kes-support-page-intro {
        font-size: 1.5em;
        line-height: 140%;
        margin: 0vw 25vw 1vw 25vw;
    }
}
