@keyframes fillingBar {
    0% {
        width: 0%;
    }
    66% {
        width: 100%;
    }
    100% {
        width: 100%;
    }
}

@keyframes fillingBarDesktop {
    0% {
        width: 0;
    }
    66% {
        width: 25vw;
    }
    100% {
        width: 25vw;
    }
}

@keyframes changeBackgroundColor {
    0% {
        background-color: transparent;
    }
    66% {
        background-color: transparent;
    }
    67% {
        background-color: var(--color-highlight);
    }
    100% {
        background-color: var(--color-highlight);
    }
}

#kes-support-modal #kes-modal-sent-message {
    animation-duration: 3s;
    animation-name: changeBackgroundColor;
    animation-timing-function: step-end;
}

#kes-support-modal #kes-modal-sent-message-square {
    animation-duration: 3s;
    animation-name: fillingBar;
    animation-timing-function: steps(6, start);
}

@media only screen and (min-width: 1200px) {
    #kes-support-modal #kes-modal-sent-message-square {
        margin-left: 35vw;
        animation-name: fillingBarDesktop;
    }
}
