#kes-floating-categories {
    display: flex;
    position: relative;
    overflow: hidden;
}

#kes-floating-categories-background {
    display: flex;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 1;
}

#kes-floating-categories-background div {
    border-radius: 100%;
    filter: blur(0.3em);
    position: absolute;
}

#kes-floating-categories-background div:nth-child(1) {
    border: 2px solid var(--color-green);
    z-index: 2;
}

#kes-floating-categories-background div:nth-child(2) {
    border: 2px solid var(--color-purple);
    z-index: 3;
}

#kes-floating-categories-background div:nth-child(3) {
    border: 2px solid var(--color-teal);
    z-index: 4;
}

#kes-floating-categories-background div:nth-child(4) {
    border: 2px solid var(--color-red);
    z-index: 5;
}

#kes-floating-categories-background div:nth-child(5) {
    border: 2px solid var(--color-yellow);
    z-index: 6;
}

#kes-floating-categories img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    position: absolute;
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    #kes-floating-categories img {
        height: 38vw;
        width: 38vw;
    }

    #kes-floating-categories,
    #kes-floating-categories-background {
        min-height: 125vw;
    }

    #category-image-capitalism {
        left: 45vw;
        top: 0vw;
    }

    #category-image-technology {
        left: 5vw;
        top: 22.25vw;
    }

    #category-image-tendences {
        left: 56.5vw;
        top: 41.5vw;
    }

    #category-image-labor {
        left: 7.75vw;
        top: 65.75vw;
    }

    #category-image-social {
        left: 49.15vw;
        top: 82.75vw;
    }

    #kes-floating-categories-background div:nth-child(1) {
        height: 3em;
        left: 15vw;
        top: 10vw;
        width: 3em;
    }

    #kes-floating-categories-background div:nth-child(2) {
        height: 4em;
        left: 80vw;
        top: 25vw;
        width: 4em;
    }

    #kes-floating-categories-background div:nth-child(3) {
        height: 5em;
        left: 45vw;
        top: 45vw;
        width: 5em;
    }

    #kes-floating-categories-background div:nth-child(4) {
        height: 6em;
        left: 70vw;
        top: 80vw;
        width: 6em;
    }

    #kes-floating-categories-background div:nth-child(5) {
        height: 7em;
        left: 5vw;
        top: 90vw;
        width: 7em;
    }
}

@media only screen and (min-width: 600px) {
    #kes-floating-categories img {
        height: 38vw;
        width: 38vw;
    }

    #kes-floating-categories,
    #kes-floating-categories-background {
        min-height: 125vw;
    }

    #category-image-capitalism {
        left: 45vw;
        top: 0vw;
    }

    #category-image-technology {
        left: 5vw;
        top: 22.25vw;
    }

    #category-image-tendences {
        left: 56.5vw;
        top: 41.5vw;
    }

    #category-image-labor {
        left: 7.75vw;
        top: 65.75vw;
    }

    #category-image-social {
        left: 49.15vw;
        top: 82.75vw;
    }

    #kes-floating-categories-background div:nth-child(1) {
        height: 3em;
        left: 15vw;
        top: 10vw;
        width: 3em;
    }

    #kes-floating-categories-background div:nth-child(2) {
        height: 4em;
        left: 80vw;
        top: 25vw;
        width: 4em;
    }

    #kes-floating-categories-background div:nth-child(3) {
        height: 5em;
        left: 45vw;
        top: 45vw;
        width: 5em;
    }

    #kes-floating-categories-background div:nth-child(4) {
        height: 6em;
        left: 70vw;
        top: 80vw;
        width: 6em;
    }

    #kes-floating-categories-background div:nth-child(5) {
        height: 7em;
        left: 5vw;
        top: 90vw;
        width: 7em;
    }
}

@media only screen and (min-width: 768px) {
    #kes-floating-categories img {
        height: 27.5vw;
        width: 27.5vw;
    }

    #kes-floating-categories,
    #kes-floating-categories-background {
        min-height: 100vw;
    }

    #category-image-capitalism {
        left: 47.5vw;
        top: 2.5vw;
    }

    #category-image-technology {
        left: 12.5vw;
        top: 14.75vw;
    }

    #category-image-tendences {
        left: 61.5vw;
        top: 34vw;
    }

    #category-image-labor {
        left: 12.75vw;
        top: 50.75vw;
    }

    #category-image-social {
        left: 49.15vw;
        top: 65.25vw;
    }

    #kes-floating-categories-background div:nth-child(1) {
        height: 6em;
        left: 15vw;
        top: 10vw;
        width: 6em;
    }

    #kes-floating-categories-background div:nth-child(2) {
        height: 8em;
        left: 80vw;
        top: 25vw;
        width: 8em;
    }

    #kes-floating-categories-background div:nth-child(3) {
        height: 10em;
        left: 50vw;
        top: 40vw;
        width: 10em;
    }

    #kes-floating-categories-background div:nth-child(4) {
        height: 12em;
        left: 75vw;
        top: 75vw;
        width: 12em;
    }

    #kes-floating-categories-background div:nth-child(5) {
        height: 14em;
        left: 10vw;
        top: 85vw;
        width: 14em;
    }
}

@media only screen and (min-width: 992px) {
    #kes-floating-categories img {
        height: 27.5vw;
        width: 27.5vw;
    }

    #kes-floating-categories,
    #kes-floating-categories-background {
        min-height: 100vw;
    }

    #category-image-capitalism {
        left: 47.5vw;
        top: 2.5vw;
    }

    #category-image-technology {
        left: 12.5vw;
        top: 14.75vw;
    }

    #category-image-tendences {
        left: 61.5vw;
        top: 34vw;
    }

    #category-image-labor {
        left: 12.75vw;
        top: 50.75vw;
    }

    #category-image-social {
        left: 49.15vw;
        top: 65.25vw;
    }

    #kes-floating-categories-background div:nth-child(1) {
        height: 6em;
        left: 15vw;
        top: 10vw;
        width: 6em;
    }

    #kes-floating-categories-background div:nth-child(2) {
        height: 8em;
        left: 80vw;
        top: 25vw;
        width: 8em;
    }

    #kes-floating-categories-background div:nth-child(3) {
        height: 10em;
        left: 50vw;
        top: 40vw;
        width: 10em;
    }

    #kes-floating-categories-background div:nth-child(4) {
        height: 12em;
        left: 75vw;
        top: 75vw;
        width: 12em;
    }

    #kes-floating-categories-background div:nth-child(5) {
        height: 14em;
        left: 10vw;
        top: 85vw;
        width: 14em;
    }
}

@media only screen and (min-width: 1200px) {
    #kes-floating-categories img {
        height: 10vw;
        width: 10vw;
    }

    #kes-floating-categories,
    #kes-floating-categories-background {
        min-height: 40vw;
    }

    #category-image-capitalism {
        left: 47.5vw;
        top: 2.5vw;
    }

    #category-image-technology {
        left: 35.5vw;
        top: 7.75vw;
    }

    #category-image-tendences {
        left: 55.5vw;
        top: 12vw;
    }

    #category-image-labor {
        left: 35.75vw;
        top: 19.75vw;
    }

    #category-image-social {
        left: 48.15vw;
        top: 21.25vw;
    }

    #kes-floating-categories-background div:nth-child(1) {
        height: 6em;
        left: 20vw;
        top: 5vw;
        width: 6em;
    }

    #kes-floating-categories-background div:nth-child(2) {
        height: 8em;
        left: 55vw;
        top: 10vw;
        width: 8em;
    }

    #kes-floating-categories-background div:nth-child(3) {
        height: 10em;
        left: 70vw;
        top: 30vw;
        width: 10em;
    }

    #kes-floating-categories-background div:nth-child(4) {
        height: 12em;
        left: 60vw;
        top: 15vw;
        width: 12em;
    }

    #kes-floating-categories-background div:nth-child(5) {
        height: 14em;
        left: 20vw;
        top: 25vw;
        width: 14em;
    }
}
