@keyframes float-category-capitalism {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-10%, 5%);
    }
}

@keyframes float-category-technology {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(5%, 5%);
    }
}

@keyframes float-category-tendences {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-7.5%, -2.5%);
    }
}

@keyframes float-category-labor {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(7.5%, -5%);
    }
}

@keyframes float-category-social {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-5%, -2.5%);
    }
}

@keyframes float-circle-1 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(500%, 50%);
    }
}

@keyframes float-circle-2 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-450%, 50%);
    }
}

@keyframes float-circle-3 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-200%, -200%);
    }
}

@keyframes float-circle-4 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-250%, 50%);
    }
}

@keyframes float-circle-5 {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(200%, -100%);
    }
}

#kes-floating-categories img,
#kes-floating-categories-background div {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

#category-image-capitalism {
    animation-name: float-category-capitalism;
}

#category-image-technology {
    animation-name: float-category-technology;
}

#category-image-tendences {
    animation-name: float-category-tendences;
}

#category-image-labor {
    animation-name: float-category-labor;
}

#category-image-social {
    animation-name: float-category-social;
}

#kes-floating-categories-background div:nth-child(1) {
    animation-name: float-circle-1;
}

#kes-floating-categories-background div:nth-child(2) {
    animation-name: float-circle-2;
}

#kes-floating-categories-background div:nth-child(3) {
    animation-name: float-circle-3;
}

#kes-floating-categories-background div:nth-child(4) {
    animation-name: float-circle-4;
}

#kes-floating-categories-background div:nth-child(5) {
    animation-name: float-circle-5;
}
